import React from "react";
import "../fonts/font-mfizz.css"
import { SkillsTechs } from "../utils/Constants";

const Skills = (props) => {
    
    return (
        <section className="resume-section p-3 p-lg-5 d-flex flex-column" id="skills">
          <div className="my-auto">
            <div className="mb-5">Working with:</div>
            <div className="text-black"><SkillsTechs /></div>
          </div>
        </section>
    )
}

export default Skills