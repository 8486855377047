import React from 'react'
import { Link } from 'react-scroll'

export const Intro = () => {
    return (
        <p>
            I am a software developer with experience building web and mobile apps.
            <br/>
            Cofounder of <Link to="inatlantis" smooth={true}>Inatlantis</Link> where we help language learners reach fluency.
        </p>
    )
}
export const SkillsTechs = () => {
    return (
        <p>
            Javascript - Typescript - React - React Native - Java - PHP - AWS
        </p>
    )
}
export const WorkLambda = () => {
    return (
        <p className="mb-4">
            A web app to simplify risk management for Moroccan small businesses, Where the solution provides risk tracking, 
            updating, and setting up an action plan to help make accurate predictions that keep the business safe from unexpected incidents.
        </p>
    )
}
export const WorkLeBit = () => {
    return (
        <p className="mb-4">
        Payroll solution for Morocco-based enterprises.
        <br/>
        A web app to manage and validate employees' data and generate a zipped XML file based on a valid format given 
        by the Moroccan tax department (Etat 9421).
        </p>
    )
}
export const WorkWebcake = () => {
    return (
        <p className="mb-4">
        A dev agency that provides web and mobile development services.
        <br/>
        I participated in building several web and mobile apps including:
        <br/><br/>
        <a href="https://apkpure.com/buymyfashion/de.flagship_one.buymyfashion" rel="noopener noreferrer" target="_blank" >BuyMyFashion</a> is a marketplace for used fashion from top brands.
        <br/>
        <a href="https://play.google.com/store/apps/details?id=com.jdbergmann.cooking" rel="noopener noreferrer" target="_blank" >Cookking</a> helps show you what you can cook with the ingredients you already have at home.
        </p>
    )
}
export const WorkDCEM = () => {
    return (
        <p className="mb-4">
        DCEM is an integrated project that aims to develop a digital tool for environmental monitoring that combines remote sensing data (satellite images) and artificial intelligence to detect and quantify plastic waste on four target beaches in Dakhla, and this includes pk25, Moussafir, Oum Labouir, and Lakhira.
        </p>
    )
}
export const WorkInatlantis = () => {
    return (
        <p className="mb-4">
            Inatlantis is a language exchange platform that connects language learners with native and advanced speakers.
            <br/><br/>
            Started with a problem <a href="https://www.linkedin.com/in/mouhcine-mitallan-71902612b" rel="noopener noreferrer" target="_blank" >Mouhcine</a> had when trying to find with whom he can practice french, 
            to organizing a few Moroccan language exchange groups, to Inatlantis as a startup serving thousands of language learners all over the world.
        </p>
    )
}

export const Oss1 = () => {
    return (
        <p className="mb-4">
            Web application for searching the nearest shops related to the user location and some other cool features.
        </p>
    )
}
export const Oss2 = () => {
    return (
        <p className="mb-4">
            Quick ideas manager.
        </p>
    )
}