import React, { Component } from 'react';
import { Element } from 'react-scroll';
import Navbar from './components/Navbar';
import Home from "./components/Home";
import Skills from './components/Skills';
import Work from './components/Work';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Navbar />
        <div className="container-fluid p-0">
          <Element name="home">
            <Home />
          </Element>
          <hr className="m-0" />
          <Element name="work">
            <Work />
          </Element>
          <hr className="m-0" />
          <Element name="skills">
            <Skills />
          </Element>
          <div className="footer">
            <span className='peace-icn' role="img" aria-label="peace">✌️</span>
          </div>
        </div>
      </div>
    );
  }
}

export default App;