import React from "react";
import { FaGithub,FaLinkedinIn,FaInstagram,FaEnvelope,  } from 'react-icons/fa'
import ProgressiveImage from "react-progressive-image";
import Profile from '../img/profile.jpg'
import ProfilePlaceholder from '../img/profile-tiny.jpg'
import { Intro } from "../utils/Constants";

const Home = (props) => {
    
    return (
        <section className="resume-section p-3 p-lg-5 d-flex d-column" id="home">
          <div className="intro-container">
            <ProgressiveImage src={Profile} placeholder={ProfilePlaceholder}>
              {src => <img src={src} alt="SALMAN" className="profile-img" loading="lazy" />}
            </ProgressiveImage>
            <div className="intro-content">
              <div className="heading">
                <h1 className="header"><span className="fname">Salman</span> <span className="lname">ElFadili</span></h1>
                {/* <h3 className="subheader">Software Developer</h3> */}
              </div>
              <div>
                <Intro />
              </div>
              <div className="social-icons">
                <a href="mailto:elfadili.salman@gmail.com?Subject=Hello%20Dev" className="icon icon-gmail" rel="noopener noreferrer" target="_blank">
                  <FaEnvelope />
                </a>
                <a href="https://github.com/Salman-EF" className="icon icon-github" rel="noopener noreferrer" target="_blank">
                  <FaGithub />
                </a>
                <a href="https://www.linkedin.com/in/salmanelfadili" className="icon icon-linkedin" rel="noopener noreferrer" target="_blank">
                  <FaLinkedinIn />
                </a>
                {/* <a href="https://twitter.com/salman_elfadili" className="icon icon-twitter" rel="noopener noreferrer" target="_blank">
                  <FaTwitter />
                </a> */}
                <a href="https://www.instagram.com/salman_elfadili" className="icon icon-instagram" rel="noopener noreferrer" target="_blank">
                  <FaInstagram />
                </a>
            </div>
            </div>
          </div>
        </section>
    )
}

export default Home