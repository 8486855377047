import React, { useEffect, useState } from "react";
import { BsSun } from "react-icons/bs"
import { FaMoon } from "react-icons/fa";

const DarkMode = () => {
  const storageName = "theme";
  const body = document.body;
  const lightTheme = "light";
  const darkTheme = "dark";
  const [theme, setTheme] = useState(localStorage.getItem(storageName) ? localStorage.getItem(storageName) : darkTheme);

  useEffect(() => {
    body.classList.add(theme);
  })

  const switchTheme = (e) => {
    if(theme === darkTheme) {
      body.classList.replace(darkTheme, lightTheme);
      localStorage.setItem(storageName, 'light');
      setTheme(lightTheme);
    } else {
      body.classList.replace(lightTheme, darkTheme);
      localStorage.setItem(storageName, 'dark');
      setTheme(darkTheme);
    }
  };

  let modeIcon = theme === 'dark' ? <BsSun /> : <FaMoon />
  return (

    <button id="darkMode"
      className={theme}
      onClick={(e) => switchTheme(e)}
    >
      { modeIcon }
    </button>
  );
};

export default DarkMode;