import React, {Component} from "react";
import { WorkWebcake, WorkLambda, WorkLeBit, WorkInatlantis, WorkDCEM } from "../../utils/Constants";

class Pros extends Component {
    
    render() {
      return (
        <div className="my-5">
            <div className="work-wrapper">
                <div id="inatlantis" className="work-cont pt-5">
                    <h4 className="card-title">Inatlantis</h4>
                    <div className="my-auto work-infos">
                        <WorkInatlantis />
                        <h5 className="m-0">Company</h5>
                        <p className="font-weight-bold"><a href="https://www.inatlantis.com/" rel="noopener noreferrer" target="_blank">Inatlantis</a></p>
                        <h5 className="m-0">Dev</h5>
                        <p>AWS | PHP | Laravel | JS | TS | React Native | Agora.io</p>
                    </div>
                </div>
                <div className="work-cont pt-5">
                    <h4 className="card-title">Dakhla Coasts Environmental Monitoring - DCEM</h4>
                    <div className="my-auto work-infos">
                        <WorkDCEM />
                        <h5 className="m-0">Visit @</h5>
                        <p className="font-weight-bold"><a href="https://onee-dcem.com/" rel="noopener noreferrer" target="_blank">onee-dcem.com</a></p>
                        <h5 className="m-0">Dev</h5>
                        <p>React | Mapbox</p>
                    </div>
                </div>
                <div className="work-cont pt-5">
                    <h4 className="card-title">TheWebcake</h4>
                    <div className="my-auto work-infos">
                        <WorkWebcake />
                        <h5 className="m-0">Company</h5>
                        <p className="font-weight-bold"><a href="http://thewebcake.com/" rel="noopener noreferrer" target="_blank">TheWebcake</a></p>
                        <h5 className="m-0">Dev</h5>
                        <p>PHP Symfony | Ionic</p>
                    </div>
                </div>
                <div className="work-cont pt-5">
                    <h4 className="card-title">LambdaSoft Risk Management</h4>
                    <div className="my-auto work-infos">
                        <WorkLambda />
                        <h5 className="m-0">Company</h5>
                        <p className="font-weight-bold">LambdaSoft</p>
                        <h5 className="m-0">Dev</h5>
                        <p>OracleDB | Java Spring-Boot | Thymeleaf | JQuery | Bootstrap</p>
                    </div>
                </div>
                <div className="work-cont pt-5">
                    <h4 className="card-title">LEBIT Simpl-IR</h4>
                    <div className="my-auto work-infos">
                        <WorkLeBit />
                        <h5 className="m-0">Company</h5>
                        <p className="font-weight-bold"><a href="http://lebit.ma" rel="noopener noreferrer" target="_blank">LEBIT</a></p>
                        <h5 className="m-0">Dev</h5>
                        <p>OracleDB | Java JSP / Servlets Hibernate | JSTL | JQuery | Bootstrap</p>
                    </div>
                </div>
            </div>
        </div>
    )
    }
}

export default Pros