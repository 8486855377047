import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Link } from 'react-scroll';
import { Navbar } from 'react-bootstrap';
import logo from '../img/favicon.ico'
import DarkModeToggle from '../components/DarkModeToggle'

class NavBar extends Component {

    closeNavbar() {
        setTimeout(function(){
            document.getElementById('responsive-navbar-nav').classList.remove('show')
            document.getElementById('responsive-navbar-toggle').classList.remove('collapsed')
        }, 1000)
    }

    render() {
    return (
        <Navbar collapseOnSelect expand="lg" fixed="top" id="sideNav">
            <Link className="navbar-brand js-scroll-trigger" to="home" spy={true} smooth={true}>
            <img src={logo} alt="logo" loading="lazy" /><span onClick={() => this.closeNavbar()}>SALMAN</span>
            </Link>
            <DarkModeToggle />
            <Navbar.Toggle id="responsive-navbar-toggle" aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <ul className="navbar-nav">
                <li className="nav-item">
                    <Link className="nav-link js-scroll-trigger" to="home" spy={true} smooth={true} 
                                onClick={() => this.closeNavbar()}>I am</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link js-scroll-trigger" to="work" spy={true} smooth={true} 
                                onClick={() => this.closeNavbar()}>I did</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link js-scroll-trigger" to="skills" spy={true} smooth={true} 
                                onClick={() => this.closeNavbar()}>I use</Link>
                </li>
                <li className="nav-item highlight">
                    <a href="https://docs.google.com/document/d/1QbKvdnYZkxcriMGBeK5LvFpmcZwGjChSy40jgaUDCyA" className="nav-link" rel="noopener noreferrer" target="_blank">
                        Resume
                    </a>
                </li>
                </ul>
            </Navbar.Collapse>
        </Navbar>
    )
    }
}

export default withRouter(NavBar)