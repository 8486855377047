import React from "react";
import Pros from "./Pros";

const WorkSection = (props) => {
    
    return (
        <section className="resume-section p-3 p-lg-5 d-flex flex-column" id="work">
          <div className="my-auto">
                <h2 className="mb-5">Work I did</h2>
                <Pros />
                {/* <Opens /> */}
          </div>
        </section>
    )
}

export default WorkSection